import React, { useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  // Mock data - replace with actual data from your backend
  const mockData = {
    lifetimeReturns: 15234,
    monthlyValue: 45678.90,
    returnRate: 3.2,
    averageOrderValue: 150.75, // New metric added
    processingTime: 18.2,
    dailyReturns: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    weeklyTrend: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      data: [320, 280, 295, 310],
    },
    dispositionData: {
      labels: ['Reinventory', 'Refurbish', 'Liquidate', 'Discard'],
      data: [40, 30, 20, 10],
    },
    categoryData: {
      labels: ['Shoes', 'Topwear', 'Bottomwear', 'Luggage', 'Accessories', 'Innerwear'],
      data: [30, 25, 20, 15, 7, 3],
    },
    defectData: {
      labels: [
        'Manufacturing Defect',
        'Size/Fit Issue',
        'Color Mismatch',
        'Damaged in Transit',
        'Quality Not as Expected',
        'Missing Components'
      ],
      data: [35, 25, 15, 12, 8, 5],
    },
    categoryBreakdownData: {
      Shoes: [10, 5, 3, 2, 1, 1],
      Topwear: [8, 7, 5, 3, 2, 1],
      Bottomwear: [6, 4, 2, 1, 1, 1],
      Luggage: [4, 3, 1, 1, 1, 1], // Existing data
      Accessories: [2, 2, 1, 1, 1, 1],
      Innerwear: [1, 1, 1, 1, 1, 1],
    },
    categoryDefectLabels: {
      Shoes: ['Sole Damage', 'Upper Damage', 'Lace Issues', 'Insole Damage', 'Color Fade', 'Stitching Issues'],
      Topwear: ['Stitching Issues', 'Fabric Tear', 'Color Fade', 'Button Missing', 'Zipper Issues', 'Neckline Damage'],
      Bottomwear: ['Zipper Issues', 'Fabric Tear', 'Button Missing', 'Color Fade', 'Stitching Issues', 'Waistband Damage'],
      Luggage: ['Handle Damaged', 'Wheel Damaged', 'Zipper Issues', 'Body Scratches', 'Lock Malfunction', 'Interior Damage'], // Updated with more labels
      Accessories: ['Buckle Damage', 'Strap Issues', 'Color Fade', 'Clip Malfunction', 'Fabric Tear', 'Size Issues'],
      Innerwear: ['Elastic Wear', 'Fabric Tear', 'Size Issues', 'Color Fade', 'Stitching Issues', 'Comfort Issues'],
    },
  };

  const categories = mockData.categoryData.labels;

  return (
    <div className="p-4 bg-gray-50">
      {/* Top Stats Row */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        <div className="bg-gray-800 rounded-lg p-4 text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
          <h2 className="text-lg font-semibold mb-1">Lifetime Returns</h2>
          <p className="text-3xl font-bold">{mockData.lifetimeReturns.toLocaleString()}</p>
        </div>
        <div className="bg-gray-800 rounded-lg p-4 text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
          <h2 className="text-lg font-semibold mb-1">Monthly Value</h2>
          <p className="text-3xl font-bold">${mockData.monthlyValue.toLocaleString()}</p>
        </div>
        <div className="bg-gray-800 rounded-lg p-4 text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
          <h2 className="text-lg font-semibold mb-1">Avg Order Value</h2> {/* Changed label */}
          <p className="text-3xl font-bold">${mockData.averageOrderValue.toFixed(2)}</p> {/* Display new metric */}
        </div>
        <div className="bg-gray-800 rounded-lg p-4 text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
          <h2 className="text-lg font-semibold mb-1">Avg Processing Time</h2>
          <p className="text-3xl font-bold">{mockData.processingTime} Seconds</p>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Main Charts Column */}
        <div className="col-span-1 md:col-span-2 space-y-4">
          {/* Daily Returns Line Chart */}
          <div className="bg-white rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
            <h3 className="text-lg font-semibold mb-2">Daily Returns Trend</h3>
            <Line
              data={{
                labels: mockData.dailyReturns.labels,
                datasets: [{
                  label: 'Returns',
                  data: mockData.dailyReturns.data,
                  borderColor: '#8B3A8C',
                  tension: 0.5, // Increased tension for smoother line
                  fill: true,
                  backgroundColor: 'rgba(139, 58, 140, 0.1)',
                }],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true, // Maintain aspect ratio
                plugins: { legend: { display: false } },
                scales: {
                  y: {
                    beginAtZero: true, // Ensure the y-axis starts at zero
                    max: Math.max(...mockData.dailyReturns.data) + 10, // Set max y-axis value to avoid dropping
                  },
                },
              }}
              height={150} // Make the graph smaller
            />
          </div>

          {/* Weekly Trend Line Chart */}
          <div className="bg-white rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
            <h3 className="text-lg font-semibold mb-2">Weekly Returns Overview</h3>
            <Line
              data={{
                labels: mockData.weeklyTrend.labels,
                datasets: [{
                  label: 'Weekly Returns',
                  data: mockData.weeklyTrend.data,
                  borderColor: '#1f0a4d',
                  tension: 0.5, // Increased tension for smoother line
                  fill: true,
                  backgroundColor: 'rgba(31, 10, 77, 0.1)',
                }],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true, // Maintain aspect ratio
                plugins: { legend: { display: false } },
                scales: {
                  y: {
                    beginAtZero: true, // Ensure the y-axis starts at zero
                    max: Math.max(...mockData.weeklyTrend.data) + 10, // Set max y-axis value to avoid dropping
                  },
                },
              }}
              height={150} // Make the graph smaller
            />
          </div>
        </div>

        {/* Pie Charts Column */}
        <div className="space-y-4">
          <div className="bg-white rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
            <h3 className="text-lg font-semibold mb-2 text-center">Disposition Distribution</h3>
            <Pie
              data={{
                labels: mockData.dispositionData.labels,
                datasets: [{
                  data: mockData.dispositionData.data,
                  backgroundColor: ['#0D2535', '#313131', '#5388D8', '#79889E', '#AEC2ED'], // Updated color palette
                }],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'bottom', fontSize: '12px' },
                  tooltip: {
                    callbacks: {
                      label: function(tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        const percentage = ((value / mockData.dispositionData.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2);
                        return `${label}: ${value} (${percentage}%)`;
                      }
                    }
                  }
                },
              }}
              height={300} // Make pie charts bigger
            />
          </div>

          <div className="bg-white rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
            <h3 className="text-lg font-semibold mb-2 text-center">Returns by Category</h3>
            <Pie
              data={{
                labels: mockData.categoryData.labels,
                datasets: [{
                  data: mockData.categoryData.data,
                  backgroundColor: [
                    '#0D2535', '#313131', '#5388D8', '#79889E', '#AEC2ED' // Updated color palette
                  ],
                }],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'bottom', fontSize: '12px' },
                  tooltip: {
                    callbacks: {
                      label: function(tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        const percentage = ((value / mockData.categoryData.data.reduce((a, b) => a + b, 0)) * 100).toFixed(2);
                        return `${label}: ${value} (${percentage}%)`;
                      }
                    }
                  }
                },
              }}
              height={300} // Make pie charts bigger
            />
          </div>
        </div>
      </div>

      {/* Category Breakdown Section */}
      <div className="bg-white rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200 mt-4 overflow-auto" style={{ maxHeight: '400px' }}> {/* Added scrollable view with max height */}
        <h3 className="text-lg font-semibold mb-2 text-center">Category wise condition Breakdown</h3>
        <div className="flex flex-wrap justify-center">
          {categories.map((category) => (
            <div key={category} className="flex-shrink-0 w-40 mx-2 mb-4"> {/* Decreased width for better visibility and added margin for spacing */}
              <h4 className="text-md font-semibold text-center">{category}</h4>
              <Pie
                data={{
                  labels: mockData.categoryDefectLabels[category], // Updated to use new labels
                  datasets: [{
                    data: mockData.categoryBreakdownData[category],
                    backgroundColor: [
                      '#0D2535', '#313131', '#5388D8', '#79889E', '#AEC2ED', // Updated color palette
                    ],
                  }],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { display: false },
                    tooltip: {
                      callbacks: {
                        label: function(tooltipItem) {
                          const label = tooltipItem.label || '';
                          const value = tooltipItem.raw || 0;
                          const percentage = ((value / mockData.categoryBreakdownData[category].reduce((a, b) => a + b, 0)) * 100).toFixed(2);
                          return `${label}: ${value} (${percentage}%)`;
                        }
                      }
                    }
                  },
                }}
                height={300} // Made pie chart bigger
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;