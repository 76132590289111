import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaTh } from 'react-icons/fa'; // Changed icon for the toggle button
import Sidebar from './components/Sidebar';
import RightBar from './components/RightBar';
import Dashboard from './components/Dashboard';
import QueryScreen from './components/QueryScreen';
import OnboardNewItem from './components/OnboardNewItem';
import UpdateItem from './components/UpdateItem';
import QualityCheckOldItem from './components/QualityCheckOldItem';
import Claims from './components/Claims';
import AllData from './components/AllData';
import QueryScreenTags from './components/QueryScreenTags';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to manage sidebar visibility

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  return (
    <Router>
      <div className="flex">
        {/* Toggle Button */}
        <button 
          onClick={toggleSidebar} 
          className="p-2 bg-gray-800 text-white rounded-lg fixed top-4 left-4 z-10 hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600 transition duration-200"
        >
          <FaTh className="text-lg" /> {/* Only the icon is displayed */}
        </button>

        {/* Sidebar */}
        {isSidebarOpen && <Sidebar />}
        
        {/* Main Content */}
        <div className={`p-10 w-full h-screen bg-gradient-to-b from-[#f8f2ff] to-[#f0f0ff] ${isSidebarOpen ? 'ml-64 mr-56' : 'ml-0 mr-0'}`}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/query" element={<QueryScreen />} />
            <Route path="/onboard" element={<OnboardNewItem />} />
            <Route path="/update" element={<UpdateItem />} />
            <Route path="/quality-check" element={<QualityCheckOldItem />} />
            <Route path="/claims" element={<Claims />} />
            <Route path="/all-data" element={<AllData />} />
            <Route path="/query-tags" element={<QueryScreenTags />} />
          </Routes>
        </div>

        {/* Right Sidebar */}
        <RightBar />
      </div>
    </Router>
  );
}

export default App;
