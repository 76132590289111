import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaPlus, FaSync, FaChartBar, FaTag, FaSearch, FaFileAlt, FaCog } from 'react-icons/fa'; // Importing icons from react-icons
import revflowLogo from '../revflow.png';

const Sidebar = () => {
  const location = useLocation(); // Get the current location

  return (
    <div className="h-screen w-72 bg-gray-800 text-white p-6 fixed shadow-lg rounded-lg overflow-y-auto font-poppins">
      <img src={revflowLogo} alt="Revflow Logo" className="w-auto h-auto mb-8" style={{ display: 'block', margin: '0' }} />
      <div className="mb-4" /> {/* Added spacing after logo */}

      <ul className="space-y-6">
          {/* <li>
            <Link to="/dashboard" className={`block p-2 rounded-lg ${location.pathname === '/dashboard' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaChartBar className="inline-block mr-2" /> Dashboard
            </Link>
          </li> */}
          <li>
            <Link to="/onboard" className={`block p-2 rounded-lg ${location.pathname === '/onboard' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaPlus className="inline-block mr-2" /> Onboard New Item
            </Link>
          </li>
          <li>
            <Link to="/update" className={`block p-2 rounded-lg ${location.pathname === '/update' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaSync className="inline-block mr-2" /> Update Item
            </Link>
          </li>
          {/* <li>
            <Link to="/query" className={`block p-2 rounded-lg ${location.pathname === '/query' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaChartBar className="inline-block mr-2" /> Query Screen
            </Link>
          </li>
          <li>
            <Link to="/query-tags" className={`block p-2 rounded-lg ${location.pathname === '/query-tags' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaTag className="inline-block mr-2" /> Query Tags
            </Link>
          </li> */}
          <li>
            <Link to="/quality-check" className={`block p-2 rounded-lg ${location.pathname === '/quality-check' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaSearch className="inline-block mr-2" /> Quality Check
            </Link>
          </li>
          {/* <li>
            <Link to="/claims" className={`block p-2 rounded-lg ${location.pathname === '/claims' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
              <FaFileAlt className="inline-block mr-2" /> Claims
            </Link>
          </li> */}
      </ul>

      <div className="absolute bottom-6 left-6 right-6">
        <Link to="/settings" className={`block p-2 rounded-lg ${location.pathname === '/settings' ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white' : 'hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600'} transition duration-200 text-lg`}>
          <FaCog className="inline-block mr-2" /> Settings v2
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
