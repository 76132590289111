import React, { useState } from 'react';

const QualityCheckOldItem = () => {
  const [itemNumber, setItemNumber] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [images, setImages] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setError('');
    setItemDetails(null);
    setResponseData(null); // Reset response data on search

    try {
      const response = await fetch(`http://52.172.7.192:8080/api/get-item-details?itemNumber=${itemNumber}`);
      if (!response.ok) {
        throw new Error('Failed to fetch item details');
      }
      const data = await response.json();
      const { status, data: itemData } = data;
      if (status === 'success') {
        setItemDetails(itemData);
      } else {
        throw new Error('Failed to fetch item details');
      }
    } catch (err) {
      setError('Error fetching item details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const convertedImages = await Promise.all(files.map(async (file) => {
      const imageBlob = await convertToPng(file);
      return imageBlob;
    }));
    setImages(prevImages => [...prevImages, ...convertedImages]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleImageUpload({ target: { files } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const convertToPng = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name.replace(/\.[^/.]+$/, "") + '.png', { type: 'image/png' }));
          }, 'image/png');
        };
        img.onerror = (err) => reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    setError('');
    setResponseData(null);

    const formData = new FormData();
    formData.append('itemNumber', itemNumber);
    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      const response = await fetch('http://52.172.7.192:8080/api/sortv2', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit images');
      }

      setResponseData(result);
    } catch (err) {
      setError('Error submitting images. Please try again.');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDeleteImages = () => {
    setImages([]);
    setResponseData(null); // Reset response data on delete images
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-[#f8f2ff] to-[#f0f0ff] p-3 md:p-6 rounded-lg font-poppins overflow-hidden">
      <h1 className="text-2xl md:text-4xl font-bold text-[#3a0980] mb-4 md:mb-8 text-center">Quality Check</h1>
      <div className="space-y-4 md:space-y-6">
        <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-2 md:space-y-0">
          <input 
            type="text" 
            placeholder="Enter SKU ID" 
            value={itemNumber} 
            onChange={(e) => setItemNumber(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            className="w-full md:flex-grow p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80"
          />
          <button 
            onClick={handleSearch} 
            className="w-full md:w-auto px-6 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
          >
            Search
          </button>
        </div>

        {loading && (
          <div className="mt-4 flex items-center justify-center">
            <div className="relative">
              <div className="animate-spin h-12 w-12 md:h-16 md:w-16 border-8 border-t-8 border-[#3a0980] rounded-full"></div>
              <div className="absolute top-0 left-0 h-12 w-12 md:h-16 md:w-16 border-8 border-t-8 border-[#3a0980]/30 rounded-full animate-ping"></div>
            </div>
            <div className="ml-4 text-[#3a0980] text-lg md:text-xl">Loading item details...</div>
          </div>
        )}

        {error && <div className="text-red-500/80 text-sm md:text-base">{error}</div>}

        {itemDetails && (
          <div className="p-3 md:p-4 border border-[#3a0980]/20 rounded-lg shadow-sm bg-white/80">
            <h2 className="text-lg md:text-xl font-semibold text-[#3a0980] mb-2">Item Details:</h2>
            <p className="text-sm md:text-base text-[#3a0980]/80">{itemDetails.description}</p>
            {itemDetails.image && (
              <div className="w-full h-auto overflow-hidden">
                <img src={`data:image/jpeg;base64,${itemDetails.image}`} alt={itemDetails.image_filename} className="w-full h-auto object-contain" />
              </div>
            )}
          </div>
        )}

        <div className="space-y-4">
          <div 
            className="border-2 border-dashed border-[#3a0980]/20 rounded-lg p-3 md:p-4 text-center bg-white/60 hover:bg-white/80 transition duration-200 cursor-pointer"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.querySelector('input[type="file"]').click()}
          >
            <p className="text-sm md:text-base text-[#3a0980]/60">Drag and drop images here or click to upload</p>
            <input 
              type="file" 
              multiple 
              onChange={handleImageUpload} 
              className="hidden"
            />
          </div>

          {images.length > 0 && (
            <div>
              <h2 className="text-xl md:text-2xl font-semibold text-[#3a0980] mb-2">Uploaded Images:</h2>
              <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
                {images.map((image, index) => (
                  <div key={index} className="w-full h-auto md:w-80 md:h-80 aspect-square overflow-hidden rounded-lg border border-[#3a0980]/20">
                    <img src={URL.createObjectURL(image)} alt={`Uploaded ${index}`} className="w-full h-full object-cover" />
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <button 
                  onClick={handleDeleteImages} 
                  className="mt-4 px-4 md:px-6 py-2 bg-red-500/80 text-white text-sm md:text-base rounded-lg hover:bg-red-600/80 transition duration-200"
                >
                  Delete All Images
                </button>
              </div>
            </div>
          )}

          <div className="flex justify-center">
            <button 
              onClick={handleSubmit} 
              className="w-full md:w-auto px-4 md:px-6 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
            >
              {submitLoading ? (
                <div className="flex items-center justify-center">
                  <div className="h-5 w-5 md:h-6 md:w-6 border-4 border-t-4 border-white rounded-full mr-2 animate-pulse"></div>
                  <span className="animate-pulse text-sm md:text-base">Processing...</span>
                </div>
              ) : 'Check Quality'}
            </button>
          </div>
        </div>

        {responseData && (
          <div className="p-4 md:p-6 border border-[#3a0980]/20 rounded-lg shadow-lg bg-white/80">
            <h2 className="text-xl md:text-2xl font-bold text-[#3a0980] mb-4">Quality Assessment Results</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div className="space-y-4">
                <div className="p-3 md:p-4 border border-[#3a0980]/20 rounded-lg bg-white/60">
                  <h3 className="font-semibold text-[#3a0980] text-sm md:text-base">Assessment Details:</h3>
                  <div className="mt-2 space-y-2">
                    {responseData.grading_reason.map((reason, index) => (
                      <div key={index} className="p-2 bg-[#3a0980]/10 rounded text-sm md:text-base">
                        <span className="font-medium text-[#3a0980]">{Object.keys(reason)[0]}: </span>
                        <span className="text-[#3a0980]/80">{Object.values(reason)[0]}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="p-3 md:p-4 border border-[#3a0980]/20 rounded-lg bg-white/60">
                  <h3 className="font-semibold text-[#3a0980] text-sm md:text-base">Summary:</h3>
                  <p className="mt-2 text-[#3a0980]/80 text-sm md:text-base">{responseData.classification_summary}</p>
                </div>
                <div className="p-3 md:p-4 border border-[#3a0980]/20 rounded-lg bg-white/60">
                  <h3 className="font-semibold text-[#3a0980] text-sm md:text-base">Grade:</h3>
                  <div className="mt-2 text-xl md:text-2xl font-bold text-[#3a0980]">{responseData.Grade}</div>
                </div>
                <div className="p-3 md:p-4 border border-[#3a0980]/20 rounded-lg bg-white/60">
                  <h3 className="font-semibold text-[#3a0980] text-sm md:text-base">Recommended Action:</h3>
                  <button className="mt-2 w-full p-2 md:p-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[#3a0980] transition duration-200 text-sm md:text-base">
                    {responseData.Classification_action}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QualityCheckOldItem;