import React from 'react';
import { Link } from 'react-router-dom';
import userIcon from '../user-icon.jpg'; // You'll need to add this image

const RightBar = () => {
  return (
    <div className="hidden md:block h-screen w-56 bg-gray-800 text-white p-6 fixed right-0 shadow-lg rounded-lg overflow-y-auto font-poppins"> {/* Added hidden md:block to hide on mobile */}
      <div className="flex items-center justify-center mb-6">
        <img src={userIcon} alt="User Icon" className="w-12 h-12 rounded-full border-2 border-white shadow-md" />
      </div>

      <div className="text-center mb-4">
        <h2 className="text-lg font-bold">John Smith</h2>
        <p className="text-xs text-gray-300">john.smith@company.com</p>
      </div>

      <div className="space-y-3">
        <div className="bg-white/10 p-3 rounded-lg shadow-lg">
          <h3 className="font-semibold text-sm mb-1">Account Status</h3>
          <p className="text-xs">Premium Member</p>
        </div>

        <div className="bg-white/10 p-3 rounded-lg shadow-lg">
          <h3 className="font-semibold text-sm mb-1">Last Login</h3>
          <p className="text-xs">2024-01-15, 15:45 PM</p>
        </div>

        <div className="bg-white/10 p-3 rounded-lg shadow-lg">
          <h3 className="font-semibold text-sm mb-1">Recent Activity</h3>
          <ul className="text-xs space-y-1">
            <li>Generated Report #789</li>
            <li>Approved Shipment #456</li>
            <li>Created New Project</li>
            <li>Updated Team Settings</li>
          </ul>
        </div>
      </div>

      <div className="absolute bottom-6 left-6 right-6 space-y-2">
        <Link to="/profile" className="block p-2 rounded-lg bg-white/20 hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600 text-center transition duration-200 text-sm shadow-md">
          Profile Settings
        </Link>
        <button className="w-full p-2 rounded-lg bg-red-500 hover:bg-red-600 transition duration-200 text-sm shadow-md">
          Logout
        </button>
      </div>
    </div>
  );
};

export default RightBar;
