import React, { useState, useEffect } from 'react';

const OnboardNewItem = () => {
  const [itemNumber, setItemNumber] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('http://52.172.7.192:8080/api/get-categories');
        const data = await response.json();
        if (data.status === 'success' && Array.isArray(data.categories)) {
          setCategories(data.categories);
        } else {
          console.error('Fetched categories is not valid:', data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const pngImages = await Promise.all(files.map(async (file) => {
      const imageBitmap = await createImageBitmap(file);
      const canvas = document.createElement('canvas');
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(imageBitmap, 0, 0);
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          const pngFile = new File([blob], file.name.replace(/\.[^/.]+$/, "") + '.png', { type: 'image/png' });
          resolve(pngFile);
        }, 'image/png');
      });
    }));
    setImages(prevImages => [...prevImages, ...pngImages]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('itemNumber', itemNumber);
    formData.append('category', selectedCategory);
    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      const response = await fetch('http://52.172.7.192:8080/api/onboard-item', {
        method: 'POST',
        body: formData,
      });

      const success = response.ok;

      if (success) {
        setMessage('Item successfully onboarded!');
      } else {
        setMessage('Error onboarding item. Please try again.');
      }
    } catch (error) {
      setMessage('Error onboarding item. Please try again.');
    } finally {
      setLoading(false);
      setItemNumber('');
      setSelectedCategory('');
      setImages([]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-[#f8f2ff] to-[#f0f0ff] p-3 md:p-6 rounded-lg font-poppins overflow-hidden">
      <h1 className="text-2xl md:text-4xl font-bold text-[#3a0980] mb-4 md:mb-8 text-center">Onboard New Item</h1>
      <div className="space-y-4 md:space-y-6">
        <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-2 md:space-y-0">
          <div className="relative w-full md:flex-grow">
            <select 
              value={selectedCategory} 
              onChange={(e) => setSelectedCategory(e.target.value)} 
              className="w-full p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80"
            >
              <option value="" disabled>Select Category</option>
              {Array.isArray(categories) && categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-2 md:space-y-0">
          <input 
            type="text" 
            placeholder="Enter SKU ID" 
            value={itemNumber} 
            onChange={(e) => setItemNumber(e.target.value)} 
            onKeyPress={handleKeyPress}
            className="w-full md:flex-grow p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80"
          />
        </div>

        <div 
          className="border-2 border-dashed border-[#3a0980]/20 rounded-lg p-3 md:p-4 text-center bg-white/60 hover:bg-white/80 transition duration-200 cursor-pointer"
          onClick={() => document.querySelector('input[type="file"]').click()}
        >
          <p className="text-sm md:text-base text-[#3a0980]/60">Click to upload images</p>
          <input 
            type="file" 
            multiple 
            onChange={handleImageUpload} 
            className="hidden"
          />
        </div>

        {images.length > 0 && (
          <div>
            <h2 className="text-xl md:text-2xl font-semibold text-[#3a0980] mb-2">Uploaded Images:</h2>
            <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
              {images.map((image, index) => (
                <div key={index} className="w-full h-auto md:w-80 md:h-80 aspect-square overflow-hidden rounded-lg border border-[#3a0980]/20">
                  <img src={URL.createObjectURL(image)} alt={`Uploaded ${index}`} className="w-full h-full object-cover" />
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-center">
          <button 
            onClick={handleSubmit} 
            className="w-full md:w-auto px-4 md:px-6 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="h-5 w-5 md:h-6 md:w-6 border-4 border-t-4 border-white rounded-full mr-2 animate-pulse"></div>
                <span className="animate-pulse text-sm md:text-base">Processing...</span>
              </div>
            ) : 'Submit'}
          </button>
        </div>

        {message && (
          <div className="p-4 border border-[#3a0980]/20 rounded-lg bg-white/80 text-center text-[#3a0980]">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardNewItem;
