import React, { useState, useEffect, useRef } from 'react';
import { Tree as D3Tree } from 'react-d3-tree';

const UpdateItem = () => {
  const [itemNumber, setItemNumber] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [decisionModel, setDecisionModel] = useState('');
  const [claimApprovalModel, setClaimApprovalModel] = useState('');
  const [specialCases, setSpecialCases] = useState('');
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [treeData, setTreeData] = useState(null);
  const treeContainerRef = useRef(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [updatingNode, setUpdatingNode] = useState(null);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedApprovalCriteria, setUpdatedApprovalCriteria] = useState('');
  const [updatedRecommendation, setUpdatedRecommendation] = useState('');
  const [newNodeName, setNewNodeName] = useState('');
  const [addingToNode, setAddingToNode] = useState(null);

  useEffect(() => {
    if (itemDetails && itemDetails.reasoning_tree) {
      setTreeData(itemDetails.reasoning_tree);
    }
  }, [itemDetails]);

  const handleSearch = async () => {
    setLoading(true);
    setError('');
    setItemDetails(null);
    setDecisionModel('');
    setClaimApprovalModel('');
    setSpecialCases('');
    setTags([]);

    try {
      const response = await fetch(`http://52.172.7.192:8080/api/get-item-details?itemNumber=${itemNumber}`);
      if (!response.ok) {
        throw new Error('Failed to fetch item details');
      }
      const data = await response.json();
      const { status, data: itemData } = data;
      if (status === 'success') {
        setItemDetails(itemData);
        setDecisionModel(itemData.decisionModel);
        setClaimApprovalModel(itemData.claimApprovalModel);
        setSpecialCases(itemData.special_cases || '');
        setTags(itemData.tags || []);
      } else {
        throw new Error('Failed to fetch item details');
      }
    } catch (err) {
      setError('Error fetching item details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDecisionModelSubmit = async () => {
    setSubmitLoading(true);
    setError('');
    setSubmitMessage('');

    try {
      const response = await fetch('http://52.172.7.192:8080/api/update-decision-model', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemNumber, decisionModel, claimApprovalModel, specialCases, tags, reasoningTree: treeData }),
      });

      if (!response.ok) {
        throw new Error('Failed to update decision model');
      }

      const result = await response.json();
      if (result.status !== 'success') {
        throw new Error('Failed to update decision model');
      }

      // New code to handle successful update response
      if (response.status === 200) {
        setSubmitMessage('Decision model updated successfully!');
        // Show success message
        setTimeout(() => {
          setSubmitMessage('');
        }, 3000);
      }
    } catch (err) {
      setError('Error updating decision model. Please try again.');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleAddChild = (nodeData) => {
    setAddingToNode(nodeData);
    setNewNodeName('');
    setShowAddPopup(true);
  };

  const handleAddConfirm = () => {
    if (newNodeName) {
      const updatedTreeData = JSON.parse(JSON.stringify(treeData));
      addChildNodeAtRightLevel(updatedTreeData, addingToNode.id, newNodeName);
      setTreeData(updatedTreeData);
      setShowAddPopup(false);
    }
  };

  const handleRemoveNode = (nodeId) => {
    const updatedTreeData = JSON.parse(JSON.stringify(treeData));
    removeNodeById(updatedTreeData, nodeId);
    setTreeData(updatedTreeData);
  };

  const removeNodeById = (node, nodeId) => {
    if (node.id === nodeId) {
      return null;
    }
    if (node.children) {
      node.children = node.children
        .map(child => removeNodeById(child, nodeId))
        .filter(child => child !== null);
    }
    return node;
  };

  const addChildNodeAtRightLevel = (node, parentId, newNodeName) => {
    if (node.id === parentId) {
      if (!node.children) node.children = [];
      node.children.push({
        id: `${parentId}-${node.children.length}-${Math.random().toString(36).substring(2, 15)}`,
        name: newNodeName,
        children: []
      });
      return true;
    } else if (node.children) {
      for (let child of node.children) {
        if (addChildNodeAtRightLevel(child, parentId, newNodeName)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleUpdateNode = (node) => {
    setUpdatingNode(node);
    setUpdatedName(node.name);
    setUpdatedDescription(node.description || '');
    setUpdatedApprovalCriteria(node.approvalCriteria || '');
    setUpdatedRecommendation(node.recommendation || '');
    setShowUpdatePopup(true);
  };

  const handleUpdateConfirm = () => {
    if (updatedName !== null && updatedDescription !== null) {
      const updatedTreeData = JSON.parse(JSON.stringify(treeData));
      updateNodeData(updatedTreeData, updatingNode.id);
      setTreeData(updatedTreeData);
      setShowUpdatePopup(false);
    }
  };

  const updateNodeData = (node, nodeId) => {
    if (node.id === nodeId) {
      node.name = updatedName;
      node.description = updatedDescription;
      node.approvalCriteria = updatedApprovalCriteria;
      node.recommendation = updatedRecommendation;
      return true;
    }
    if (node.children) {
      for (let child of node.children) {
        if (updateNodeData(child, nodeId)) {
          return true;
        }
      }
    }
    return false;
  };

const renderReasoningTree = () => {
  if (!treeData) return null;

  const containerStyles = {
    width: '100%',
    height: '800px',
    border: '1px solid #3a0980',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    backgroundColor: 'white/80',
    position: 'relative',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    padding: '1rem', // Added padding for better spacing
  };

  return (
    <div style={containerStyles} ref={treeContainerRef}>
      <D3Tree
        data={treeData}
        orientation="vertical"
        translate={{ x: 400, y: 80 }}
        nodeSize={{ x: 280, y: 120 }}
        separation={{ siblings: 1, nonSiblings: 2.5 }}
        zoomable={true} // Enable zooming
        scaleExtent={{ min: 0.5, max: 2 }} // Allow more zooming
        renderCustomNodeElement={(rd3tProps) => (
          <g>
            <rect
              width="240"
              height="100"
              x="-120"
              y="-50"
              rx="12" // Increased border radius for a softer look
              ry="12"
              fill="white"
              stroke="#3a0980"
              strokeWidth="1.5"
              filter="drop-shadow(0 4px 6px rgb(0 0 0 / 0.05))"
            />
            <text
              x="0"
              y="-10"
              textAnchor="middle"
              style={{
                fontSize: '20px', // Reduced font size for better spacing
                fontFamily: 'sans-serif',
                fontWeight: rd3tProps.nodeDatum.depth < 2 ? '200' : '400', // Darker text for layer 1 and 2
                fill: '#3a0980',
                letterSpacing: '0.07em' // Increased letter spacing for clarity
              }}
            >
              {rd3tProps.nodeDatum.name}
            </text>

            <foreignObject x="-110" y="-10" width="220" height="80">
              <div className="flex flex-col items-center justify-center h-full">
                <div className="flex justify-between w-full gap-3">
                  <button
                    onClick={() => handleAddChild(rd3tProps.nodeDatum)}
                    className="px-4 py-1.5 text-xs font-medium text-white bg-gradient-to-r from-[#3a0980] to-[#8f4df7] rounded-md hover:from-[#8f4df7] hover:to-[#3a0980] transition-colors shadow-md"
                  >
                    Add
                  </button>
                  <button
                    onClick={() => handleUpdateNode(rd3tProps.nodeDatum)}
                    className="px-4 py-1.5 text-xs font-medium text-white bg-gradient-to-r from-[#3a0980] to-[#8f4df7] rounded-md hover:from-[#8f4df7] hover:to-[#3a0980] transition-colors shadow-md"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleRemoveNode(rd3tProps.nodeDatum.id)}
                    className="px-4 py-1.5 text-xs font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-md hover:from-red-600 hover:to-red-500 transition-colors shadow-md"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </foreignObject>
          </g>
        )}
        pathClassFunc={() => 'tree-link'}
        pathFunc="step"
      />
    </div>
  );
};

  const popupStyles = {
    overlay: "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center",
    container: "bg-white/80 rounded-lg p-6 w-96 border border-[#3a0980]/20",
    title: "text-2xl font-semibold text-[#3a0980] mb-4",
    input: "w-full p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80 mb-4",
    textarea: "w-full p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80 mb-4",
    buttonContainer: "flex justify-end space-x-2",
    cancelButton: "px-4 py-2 text-[#3a0980]/60 hover:text-[#3a0980] transition-colors",
    confirmButton: "px-4 py-2 bg-[#3a0980]/80 text-white rounded-md hover:bg-[#3a0980] transition-colors"
  };

  return (
    <div className="h-screen w-full bg-gradient-to-b from-[#f8f2ff] to-[#f0f0ff] p-6 rounded-lg overflow-hidden font-poppins">
      <h1 className="text-4xl font-bold text-[#3a0980] mb-8 text-center">Update Decision Model</h1>
      <div className="space-y-6 max-h-[calc(100vh-150px)] overflow-y-auto">
        <div className="flex flex-col md:flex-row justify-center space-x-0 md:space-x-4">
          <input
            type="text"
            placeholder="Enter SKU ID"
            value={itemNumber}
            onChange={(e) => setItemNumber(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            className="flex-grow p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80 mb-4 md:mb-0"
          />
          <button
            onClick={handleSearch}
            className="px-6 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
          >
            Search
          </button>
        </div>

        {loading && (
          <div className="mt-4 flex items-center justify-center">
            <div className="relative">
              <div className="animate-spin h-16 w-16 border-8 border-t-8 border-[#3a0980] rounded-full"></div>
              <div className="absolute top-0 left-0 h-16 w-16 border-8 border-t-8 border-[#3a0980]/30 rounded-full animate-ping"></div>
            </div>
            <div className="ml-4 text-[#3a0980] text-xl">Loading item details...</div>
          </div>
        )}

        {error && <div className="text-red-500/80">{error}</div>}

        {itemDetails && (
          <div className="space-y-6">
            <div className="p-4 border border-[#3a0980]/20 rounded-lg shadow-sm bg-white/80">
              <h2 className="text-xl font-semibold text-[#3a0980] mb-4">Item Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-[#3a0980]/80 text-lg"> {/* Increased font size */}
                    <strong>SKU ID:</strong> <span className="font-semibold">{itemDetails.itemNumber}</span>
                  </p>
                  <p className="text-[#3a0980]/80 text-lg"> {/* Increased font size */}
                    <strong>Description:</strong> {itemDetails.description}
                  </p>
                </div>
                {itemDetails.image && (
                  <div className="flex justify-center">
                    <img 
                      src={`data:image/jpeg;base64,${itemDetails.image}`}
                      alt={itemDetails.image_filename}
                      className="w-full h-48 object-cover rounded-lg shadow-md"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="p-4 border border-[#3a0980]/20 rounded-lg shadow-sm bg-white/80">
              <h3 className="text-xl font-semibold text-[#3a0980] mb-4">Reasoning Tree</h3>
              {renderReasoningTree()}
            </div>

            <div className="p-4 border border-[#3a0980]/20 rounded-lg shadow-sm bg-white/80">
              <h3 className="text-xl font-semibold text-[#3a0980] mb-4">Checklist</h3>
              <textarea
                value={decisionModel}
                onChange={(e) => setDecisionModel(e.target.value)}
                className="w-full h-48 p-4 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80"
              />
            </div>

            <div className="p-4 border border-[#3a0980]/20 rounded-lg shadow-sm bg-white/80">
              <h3 className="text-xl font-semibold text-[#3a0980] mb-4">Tags</h3>
              <div className="flex flex-wrap gap-2 mb-4">
                {tags.map((tag, index) => (
                  <div key={index} className="flex items-center bg-[#3a0980]/10 px-3 py-1 rounded-full">
                    <span className="text-[#3a0980]">{tag}</span>
                    <button
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Add new tag"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="flex-grow p-3 border border-[#3a0980]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3a0980]/50 bg-white/80"
                />
                <button
                  onClick={handleAddTag}
                  className="px-6 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
                >
                  Add Tag
                </button>
              </div>
            </div>

            <div className="flex justify-center mt-8">
              <button
                onClick={handleDecisionModelSubmit}
                className="px-8 py-3 bg-[#3a0980]/80 text-white rounded-lg hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:from-[#FA93FA] hover:to-[#8f4df7] transition duration-200"
                disabled={submitLoading}
              >
                {submitLoading ? 'Updating...' : 'Update SKU'}
              </button>
            </div>
            {submitMessage && (
              <div className="mt-4 text-center text-green-500/80">
                {submitMessage}
              </div>
            )}
          </div>
        )}
      </div>

      {showUpdatePopup && (
        <div className={popupStyles.overlay}>
          <div className={popupStyles.container}>
            <h3 className={popupStyles.title}>Update Node</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
                placeholder="Node Name"
                className={popupStyles.input}
              />
              <textarea
                value={updatedDescription}
                onChange={(e) => setUpdatedDescription(e.target.value)}
                placeholder="Node Description"
                className={popupStyles.textarea}
              />
              <textarea
                value={updatedApprovalCriteria}
                onChange={(e) => setUpdatedApprovalCriteria(e.target.value)}
                placeholder="Approval Criteria"
                className={popupStyles.textarea}
              />
              <textarea
                value={updatedRecommendation}
                onChange={(e) => setUpdatedRecommendation(e.target.value)}
                placeholder="Recommendation"
                className={popupStyles.textarea}
              />
              <div className={popupStyles.buttonContainer}>
                <button
                  onClick={() => setShowUpdatePopup(false)}
                  className={popupStyles.cancelButton}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateConfirm}
                  className={popupStyles.confirmButton}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddPopup && (
        <div className={popupStyles.overlay}>
          <div className={popupStyles.container}>
            <h3 className={popupStyles.title}>Add New Node</h3>
            <input
              type="text"
              value={newNodeName}
              onChange={(e) => setNewNodeName(e.target.value)}
              placeholder="New Node Name"
              className={popupStyles.input}
            />
            <div className={popupStyles.buttonContainer}>
              <button
                onClick={() => setShowAddPopup(false)}
                className={popupStyles.cancelButton}
              >
                Cancel
              </button>
              <button
                onClick={handleAddConfirm}
                className={popupStyles.confirmButton}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateItem;